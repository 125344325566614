/* tslint:disable */
import { IImageSettings } from '@msdyn365-commerce/core';
import { Module, Node } from '@msdyn365-commerce-modules/utilities';
import * as React from 'react';
import { ISearchResultModalViewProps } from '@msdyn365-commerce-modules/search-result-container/src/modules/search-result-container/./components';
import { ICategoryHierarchyViewProps, IRefineMenuViewProps, ISearchResultContainerViewProps, ISortByViewProps, ITitleViewProps } from '@msdyn365-commerce-modules/search-result-container/src/modules/search-result-container/./search-result-container';

    const SearchResultContainerView: React.FC<ISearchResultContainerViewProps> = props => {
    /*RA - 10152020 - theme settings doesn't work, so we have to manually do this*/   
    const fhiDefaultSearchResultSettings: IImageSettings = {
        viewports: {
            xs: { q: 'w=315&h=315&q=80&m=8&f=jpg', w: 0, h: 0 },
            sm: { q: 'w=315&h=315&q=80&m=8&f=jpg', w: 0, h: 0 },
            md: { q: 'w=315&h=315&q=80&m=8&f=jpg', w: 0, h: 0 },
            lg: { q: 'w=315&h=315&q=80&m=8&f=jpg', w: 0, h: 0 },
            xl: { q: 'w=315&h=315&q=80&m=8&f=jpg', w: 0, h: 0 }
        },
        lazyload: true,
        quality: 80
    };   
    const moduleProperties:any = props.products;
    moduleProperties.props.imageSettings = fhiDefaultSearchResultSettings;
    /*RA - 10152020 - End*/
    const { SearchResultContainer, products, pagination, ProductsContainer, ProductSectionContainer, choiceSummary, isMobile, modalToggle, searchResultModal, TitleViewProps,
        refineMenu, categoryHierarchy, sortByOptions, CategoryNavContainer, RefineAndProductSectionContainer, errorMessage, FeatureSearchContainer, similarLookProduct } = props;
    if (isMobile) {
        return (
            <Module {...SearchResultContainer}>
                {renderCategoryHierarchy(categoryHierarchy)}
                {renderTitle(TitleViewProps)}
                {choiceSummary}
                {modalToggle}
                {createSearchResultModal(searchResultModal, refineMenu, sortByOptions)}
                <Node {...FeatureSearchContainer}>
                    {similarLookProduct}
                </Node>
                <Node {...ProductsContainer}>
                    {errorMessage}
                    {products}
                </Node>
                {pagination}
            </Module>
        );
    }
    return (
        <Module {...SearchResultContainer}>
        <Node {...CategoryNavContainer}>
        {categoryHierarchy && renderCategoryHierarchy(categoryHierarchy)}        
        </Node>
        <Node {...RefineAndProductSectionContainer}>
        {refineMenu && renderRefiner(refineMenu)}
        <Node {...ProductSectionContainer}>
            <div className='ms-search-result-wrapper-title-choice-summary'>               
                {TitleViewProps && renderTitle(TitleViewProps)}
                {choiceSummary}
            </div>
            <div className='ms-search-result-wrapper-sort-by-category'>
                {sortByOptions && renderSort(sortByOptions)}
            </div>
            <Node {...FeatureSearchContainer}>
                    {similarLookProduct}
                </Node>
            <Node {...ProductsContainer}>
               {errorMessage}
                {products}
            </Node>
            {pagination}
        </Node>
        </Node>
    </Module>
    );

};

const createSearchResultModal = (modalProps: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps): JSX.Element => {
    return React.cloneElement(modalProps.modal, {}, modalProps.modalHeader, createModalBody(modalProps, refineMenu, sortByDropDown), modalProps.modalFooter);
};

const createModalBody = (props: ISearchResultModalViewProps, refineMenu: IRefineMenuViewProps, sortByDropDown: ISortByViewProps): JSX.Element | null => {
    if (sortByDropDown) {
        return React.cloneElement(props.modalBody, {}, renderSort(sortByDropDown), renderRefiner(refineMenu));
    }
    return null;
};

const renderRefiner = (props: IRefineMenuViewProps): JSX.Element | null => {
    const { refiners, RefineMenuContainer, RefinerSectionContainer } = props;
    if (refiners) {
        return (
            <Node {...RefinerSectionContainer}>
                <Node {...RefineMenuContainer}>
                    {refiners.map((submenu, index) => (
                        <React.Fragment key={index}>
                            {submenu}
                        </React.Fragment>
                    ))}
                </Node>
            </Node>
        );
    }
    return null;
};

const renderSort = (props: ISortByViewProps): JSX.Element | null => {
    const { SortingContainer, sortByDropDown } = props;
    if (sortByDropDown) {
        return (
            <Node {...SortingContainer}>
                {sortByDropDown}
            </Node>
        );
    }
    return null;
};

const renderCategoryHierarchy = (props: ICategoryHierarchyViewProps): JSX.Element | null => {
    const { CategoryHierarchyContainer, categoryHierarchyList, categoryHierarchySeparator } = props;
    if (categoryHierarchyList) {
        return (
            <Node {...CategoryHierarchyContainer}>
                {categoryHierarchyList.map((category, index) => (
                    <React.Fragment key={index}>
                        {category}
                        {categoryHierarchyList && categoryHierarchyList[index + 1] && categoryHierarchySeparator}
                    </React.Fragment>
                ))}
            </Node>
        );
    }

    return null;
};

const renderTitle = (props: ITitleViewProps): JSX.Element | null => {
    const { title, TitleContainer } = props;
    if (title) {
        return (
            <Node {...TitleContainer}>
                <h2>
                    {title.titlePrefix}
                    {title.titleText}
                    ({title.titleCount})
                </h2>
            </Node>
        );
    }
    return null;
};

export default SearchResultContainerView;