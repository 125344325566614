import { IImageSettings } from '@msdyn365-commerce/core';
export const updateImageSettingswithZoom = (imageSettings?: IImageSettings) => {
    let queryString:string = '';
    if (imageSettings?.viewports?.lg?.q) {
        queryString = imageSettings.viewports.lg.q;
        queryString = queryString.replace('m=6', 'm=8');
        imageSettings.viewports.lg.q = queryString;
    }
    if (imageSettings?.viewports?.md?.q) {
        queryString = imageSettings.viewports.md.q;
        queryString = queryString.replace('m=6', 'm=8');
        imageSettings.viewports.md.q = queryString;
    }
    if (imageSettings?.viewports?.xs?.q) {
        queryString = imageSettings.viewports.xs.q;
        queryString = queryString.replace('m=6', 'm=8');
        imageSettings.viewports.xs.q = queryString;
    }
    if (imageSettings?.viewports?.xl?.q) {
        queryString = imageSettings.viewports.xl.q;
        queryString = queryString.replace('m=6', 'm=8');
        imageSettings.viewports.xl.q = queryString;
    }
};